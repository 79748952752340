import axios from "axios";
import { deleteCustomers } from "../../redux/slices/CustomersSlice";
import { BASE_URL } from "../config";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common.crossDomain = true;

async function DeleteUser(idClient, dispatch) {
  let token = localStorage.getItem("token");
  if (token !== 'undefined') {
    token = JSON.parse(token);
  }
  const { error } = await axios.post(
    `${BASE_URL}/client/delete`,
    { idClient: idClient },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
  if (error) {
    console.log(error);
  } else {
    dispatch && dispatch(deleteCustomers(idClient));
  }
}

export { DeleteUser };

import axios from "axios";
import { addCustomers } from "../../redux/slices/CustomersSlice";
import { BASE_URL } from "../config";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common.crossDomain = true;

async function CreateUser(firstname, lastname, email, supervisor, dispatch) {
  let token = localStorage.getItem("token");
  if (token !== 'undefined') {
    token = JSON.parse(token);
  }
  console.log("token", token);
  const customers = {
    firstname: firstname,
    lastname: lastname,
    email: email,
    isSupervisor: supervisor === true ? 1 : 0,
  };
  const { error } = await axios.post(`${BASE_URL}/client/create`, customers, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (error) {
    console.log(error);
  } else {
    dispatch && dispatch(addCustomers(customers));
  }
}

export { CreateUser };

import axios from 'axios';
import { BASE_URL } from "../config";

export const getStats = async () => {
    let userId = localStorage.getItem("userId");
    if (userId !== 'undefined') {
        userId = JSON.parse(userId);
    }

    if (userId) {
      return axios
        .post(`${BASE_URL}/auth/stats`, {
            idClient: userId
        })
        .then( (response) => {
            console.log("API RESPONSE : ",response.data);
            return response.data

        })
        .catch(e => {
          alert(JSON.stringify(e.userId.data));
          return e.userId;
        });
    }
  };

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import OneSignal from "react-onesignal";

const root = ReactDOM.createRoot(document.getElementById("root"));

OneSignal.init({
  appId: "4e82c9ea-920d-4da1-a353-f5e046e13ff9",
  subdomainName: "hdvs-client",
  allowLocalhostAsSecureOrigin: true,
  // serviceWorkerPath: "./OneSignalSDKWorker.js",
});
OneSignal.showSlidedownPrompt();
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

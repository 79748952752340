import axios from "axios";
import { BASE_URL } from "../config";
// axios.defaults.headers.common = {
//   "X-Requested-With": "XMLHttpRequest",
//   "X-CSRF-TOKEN": window.csrf_token,
// };
// function readCookie(name) {
//   var match = document.cookie.match(
//     new RegExp("(^|;s*)(" + name + ")=([^;]*)")
//   );
//   return match ? decodeURIComponent(match[3]) : null;
// }

// const token = readCookie("CSRF-TOKEN");
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.headers.common.crossDomain = true;
// window.axios.defaults.baseURL = '/api';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

// let token = document.querySelector('meta[name="csrf-token"]');

// if (token) {
//   window.axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

//   // $.ajaxSetup({
//   //     headers: {
//   //         'X-CSRF-TOKEN': token.content
//   //     }
//   // });
// } else {
//   console.error(
//     "CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token"
//   );
// }
async function Login(email, password) {
  let response = await axios
    .post(
      `${BASE_URL}/client/login`,
      { email: email, password: password }
      // headers: {
      //   // "X-Requested-With": "XMLHttpRequest",
      //   "Content-Type": "application/json",
      //   // "X-CSRF-TOKEN": token,
      //   // "X-CSRF-TOKEN": window.csrf_token,
      // },
    )
    // .then((data)=> return data.data)
    .catch((error) => console.log(error));
  return response.data;
}

export { Login };

import { createSlice } from "@reduxjs/toolkit";

export const customersSlice = createSlice({
  name: "customers",
  initialState: {
    customers: [],
  },
  reducers: {
    setCustomers: (state, action) => {
      state.customers = action.payload;
    },
    addCustomers: (state, action) => {
      state.customers.data = [...state.customers.data, action.payload];
    },
    deleteCustomers: (state, action) => {
      state.customers.data = state.customers.data.filter(
        (customer) => customer.id !== action.payload
      );
    },
    updateCustomers: (state, action) => {
      state.customers.data = state.customers.data.map((customer) => {
        return customer.id === action.payload.idClient
          ? (customer = action.payload)
          : customer;
      });
    },
  },
});

export const { setCustomers, addCustomers, deleteCustomers, updateCustomers } =
  customersSlice.actions;
export default customersSlice.reducer;

import React from "react";
import styled from "styled-components";

function AddUserForm({
  currentData,
  firstname,
  lastname,
  email,
  onPress,
  onEmailChange,
  onLastnameChange,
  onFirstnameChange,
  isSupervisor,
  supervisor,
  handleCheck,
}) {
  return (
    <MainContainer>
      <LabelContainer>
        <Label>Adresse mail</Label>
        <Input
          type="text"
          name="name"
          defaultValue={currentData?.email}
          value={email ? email : currentData?.email}
          onChange={onEmailChange}
        />
      </LabelContainer>

      <LabelContainer>
        <Label>Nom</Label>
        <Input
          type="text"
          name="name"
          value={lastname ? lastname : currentData?.lastname}
          onChange={onLastnameChange}
        />
      </LabelContainer>
      <LabelContainer>
        <Label>Prénom</Label>
        <Input
          type="text"
          name="name"
          value={firstname ? firstname : currentData?.firstname}
          onChange={onFirstnameChange}
        />
      </LabelContainer>
      {isSupervisor === "1" && (
        <>
          <Label>Superviseur</Label>
          <input
            type="checkbox"
            name="isSupervisor"
            checked={
              supervisor
                ? supervisor
                : currentData?.isSupervisor === 1
                ? true
                : false
            }
            onChange={handleCheck}
          />
        </>
      )}

      <Button onClick={onPress}>Valider</Button>
    </MainContainer>
  );
}

const MainContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LabelContainer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
`;

// const LabelContainerRow = styled.label`
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   margin: 10px;
// `;

const Label = styled.label`
  color: #254c81;
  /* color: blue; */
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const Input = styled.input`
  height: 45px;
  width: 25vw;
  border-radius: 10px;
  border-color: #d2dae5;
  border: none;
  box-shadow: 2px 5px 8px -2px rgba(0, 0, 0, 0.1);
`;

// const CheckBox = styled.input``;

const Button = styled.button`
  width: 18vw;
  height: 45px;
  border-radius: 10px;
  border: none;
  color: #254c81;
  font-weight: bold;
  font-size: 16px;
  background-color: #f1c342;
  margin: 20px;
`;

// const Image = styled.img`
//   margin: 5px;
//   height: 40px;
//   color: ${({ color }) => (color ? color : "red")};
// `;

export default AddUserForm;

import axios from "axios";
import { setCustomers } from "../../redux/slices/CustomersSlice";
import { BASE_URL } from "../config";

async function getList(dispatch) {
  let token = localStorage.getItem("token");
  if (token !== 'undefined') {
    token = JSON.parse(token);
  }
  const { data: customers, error } = await axios.get(
    `${BASE_URL}/client/list`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (error) {
    console.log(error);
  } else {
    dispatch(setCustomers(customers));
  }
}

export { getList };

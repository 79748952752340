import styled from "styled-components";

function LoginForm({
  onPress,
  email,
  password,
  error,
  onEmailChange,
  onPasswordChange,
  onSubmit,
}) {
  return (
    <MainContainer onSubmit={onSubmit}>
      <LabelError>{error}</LabelError>
      <LabelContainer>
        <Label>Adresse mail</Label>
        <Input type="text" name="name" value={email} onChange={onEmailChange} />
      </LabelContainer>
      <LabelContainer>
        <Label>Mot de passe</Label>
        <Input
          type="password"
          name="name"
          value={password}
          onChange={onPasswordChange}
        />
      </LabelContainer>
      <Button onClick={onPress}>Se connecter</Button>
    </MainContainer>
  );
}

const MainContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LabelContainer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
`;

const Label = styled.label`
  color: #d2dae5;
  font-size: 12px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const LabelError = styled.label`
  color: #d0312D;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 10px;
`;

const Input = styled.input`
  height: 45px;
  width: 25vw;
  border-radius: 10px;
  border: none;
`;

const Button = styled.button`
  width: 18vw;
  height: 45px;
  border-radius: 10px;
  border: none;
  color: #254c81;
  font-weight: bold;
  font-size: 16px;
  background-color: #f1c342;
  margin: 20px;
`;

export default LoginForm;

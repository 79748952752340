import { useState } from "react";
import styled from "styled-components";
import LoginForm from "../components/LoginForm";
import logo from "../assets/logo_transp.png";
import { useNavigate } from "react-router-dom";
import { Login } from "../api/login";
import OneSignal from "react-onesignal";
import { oneSignalAdd } from "../api/onesignal/addOneSignalId";

function LoginScreen() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [, setToken] = useState("");
  const [user, setUser] = useState({});

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await Login(email, password).then((res) => {
      setUser(res);
      if (res.success === false) {
        setError(res.message);
        console.log(error);
      }
      OneSignal.getUserId(function (userId) {
        oneSignalAdd(userId);
      });
    });
    if (user.token) {
      setToken(user.token);
      navigate(`/home`);
    }
  };

  if (user) {
    localStorage.setItem("token", JSON.stringify(user.token));
    localStorage.setItem("userId", JSON.stringify(user.data?.id));
    localStorage.setItem(
      "isSupervisor",
      JSON.stringify(user.data?.isSupervisor)
    );
  }

  if (user.token) {
    navigate(`/home`);
  }

  return (
    <div className="App appBackground centered">
      <MainContainer>
        <Image src={logo} alt="Logo" />
        <LoginForm
          onPress={handleSubmit}
          email={email}
          password={password}
          error={error}
          onEmailChange={onEmailChange}
          onPasswordChange={onPasswordChange}
        />
      </MainContainer>
    </div>
  );
}

const MainContainer = styled.div`
  border: 1px solid white;
  border-radius: 25px;
  padding-left: 50px;
  padding-right: 50px;
`;

const Image = styled.img`
  background-color: transparent;
  height: 25vh;
`;

export default LoginScreen;

import axios from "axios";
import { BASE_URL } from "../config";

export const oneSignalDelete = async (oneSignalId) => {
  if (oneSignalId !== "") {
    let response = await axios
      .post(`${BASE_URL}/onesignal_id/delete`, {
        onesignal_id: oneSignalId,
      })

      .catch((error) => console.log(error));
    return response.data;
  }
};

import styled from "styled-components";

function Separator({ width, color, opacity }) {
  return <Main width={width} color={color} opacity={opacity}></Main>;
}

const Main = styled.div`
  /* height: 1px; */
  border: 0.5px solid;
  opacity: ${({ opacity }) => (opacity ? opacity : 1)};
  width: ${({ width }) => (width ? width : 0)}px;
  color: ${({ color }) => (color ? color : "black")};
`;

export default Separator;

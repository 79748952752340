import styled from "styled-components";
import logo from "../assets/logo_transp.png";
import exit from "../assets/icons/logout.png";
import visitorsIcon from "../assets/icons/user.png";
import reportingIcon from "../assets/icons/broadcast.png";
import CounterCard from "../components/CounterCard";
import Separator from "../components/Separator";
import UserList from "../components/UserList";
import { IconContext } from "react-icons";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CreateUser } from "../api/user/create";

import Modal from "react-modal";
import "reactjs-popup/dist/index.css";
import AddUserForm from "../components/AddUserForm";
import ReactModal from "react-modal";
import { DeleteUser } from "../api/user/delete";
import { UpdateUser } from "../api/user/update";
import useCustomers from "../redux/hooks/useCustomers";
import { useDispatch, useSelector } from "react-redux";
import { getCustomers } from "../redux/selectors";
import { getStats } from "../api/stats/stats";
import { oneSignalDelete } from "../api/onesignal/deleteOneSignalId";
import OneSignal from "react-onesignal";

function Home() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [idClient, setIdClient] = useState();
  const [isAddModal, setIsAddModal] = useState(false);
  const [supervisor, setSupervisor] = useState();

  const customers = useSelector(getCustomers);
  const dispatch = useDispatch();
  useCustomers();

  const onAddPress = async (e) => {
    setFirstname("");
    setLastname("");
    setEmail("");
    e.preventDefault();
    await CreateUser(firstname, lastname, email, supervisor, dispatch);
    closeAddModal();
  };
  let subtitle;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [addModalIsOpen, setAddIsOpen] = useState(false);
  const [updateCustomer, setUpdateCustomer] = useState();

  function openModal(customer) {
    setIdClient(customer?.id);
    setUpdateCustomer(customer);
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }
  function afterAddOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  function closeAddModal() {
    setAddIsOpen(false);
  }
  const [userToken, setUserToken] = useState("");

  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token !== 'undefined') {
      token = JSON.parse(token);
    }
    if (token) {
      setUserToken(token);
    }
    fetchStats();
  }, [userToken]);

  const [stats, setStats] = useState();

  const fetchStats = async () => {
    let response = await getStats();
    if (response) {
      setStats(response);
      console.log(response);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  ReactModal.setAppElement("#root");

  const onEmailChange = (event) => {
    setUpdateCustomer({ ...updateCustomer, email: event.target.value });
    setEmail(event.target.value);
  };

  const onFirstnameChange = (event) => {
    setUpdateCustomer({ ...updateCustomer, firstname: event.target.value });
    setFirstname(event.target.value);
  };
  const onLastnameChange = (event) => {
    setUpdateCustomer({ ...updateCustomer, lastname: event.target.value });
    setLastname(event.target.value);
  };
  const handleCheck = () => {
    setUpdateCustomer({ ...updateCustomer, isSupervisor: !supervisor });
    console.log("SUPERVISOR HOMESCREEN : ", supervisor);
    setSupervisor(!supervisor);
  };

  const onDeconnexionPress = () => {
    localStorage.removeItem("token");
    OneSignal.getUserId(function (userId) {
      oneSignalDelete(userId);
    });

    navigate("/");
  };

  const openAddModal = () => {
    setIsAddModal(true);
    setAddIsOpen(true);
  };
  console.log("SUPERVISOR : ", supervisor);

  return (
    <div>
      <Header>
        <TopHeader>
          <div style={{ width: "115px", margin: "20px" }}></div>
          <Image src={logo} alt="Logo" />

          <Button onClick={onDeconnexionPress}>
            <LogoutImage src={exit} alt="Logout" />
            Déconnexion
          </Button>
        </TopHeader>

        {(stats?.stats !== false || stats.data === null) && (
          <BottomHeader>
            <CounterCard
              icon={visitorsIcon}
              count={stats?.totalUsers}
              title={"Visiteurs uniques"}
              color={"#F1C342"}
              type={"visitor"}
            />
            <CounterCard
              icon={reportingIcon}
              count={stats?.totalTrigger}
              title={"Signalements"}
              color={"#254C81"}
              type={"report"}
            />
          </BottomHeader>
        )}
      </Header>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ListContainer>
          <ListHeader>
            <ListHeaderTitle>
              Liste des employés ({customers.data?.length ?? 0})
            </ListHeaderTitle>
            <AddButton>
              <IconContext.Provider value={{ color: "white", size: "20px" }}>
                <MdOutlinePersonAddAlt />
              </IconContext.Provider>

              <button
                onClick={openAddModal}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white",
                }}
              >
                Ajouter
              </button>
              <Modal
                isOpen={addModalIsOpen}
                onAfterOpen={afterAddOpenModal}
                onRequestClose={closeAddModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <AddUserForm
                  email={email}
                  firstname={firstname}
                  lastname={lastname}
                  onEmailChange={onEmailChange}
                  onFirstnameChange={onFirstnameChange}
                  onLastnameChange={onLastnameChange}
                  isSupervisor={localStorage.getItem("isSupervisor")}
                  supervisor={supervisor}
                  handleCheck={handleCheck}
                  onPress={onAddPress}
                />
              </Modal>
            </AddButton>
          </ListHeader>
          <Separator width={580} color={"black"} opacity={0.2} />
          {customers &&
            customers.data?.map((item, index) => {
              const onDeletePress = async () => {
                await DeleteUser(item.id, dispatch);
              };

              const onUpdatePress = async (e) => {
                e.preventDefault();
                await UpdateUser(
                  idClient,
                  firstname,
                  lastname,
                  email,
                  supervisor,
                  dispatch
                );
                closeModal();
              };
              return (
                <>
                  <UserList
                    key={index}
                    lastname={item.lastname}
                    id={item.id}
                    firstname={item.firstname}
                    email={item.email}
                    onDeletePress={onDeletePress}
                    onUpdatePress={() => openModal(item)}
                  />
                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <AddUserForm
                      currentData={updateCustomer}
                      email={email}
                      firstname={firstname}
                      lastname={lastname}
                      onEmailChange={onEmailChange}
                      onFirstnameChange={onFirstnameChange}
                      onLastnameChange={onLastnameChange}
                      isSupervisor={localStorage.getItem("isSupervisor")}
                      supervisor={supervisor}
                      handleCheck={handleCheck}
                      onPress={!isAddModal ? onUpdatePress : undefined}
                    />
                  </Modal>
                </>
              );
            })}
        </ListContainer>
      </div>
    </div>
  );
}

const Header = styled.div`
  width: 100vw;
  height: 30vh;
  background-color: #254c81;
  margin-bottom: 100px;
`;

const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50%;
`;

const AddButton = styled.button`
  padding-right: 10px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  flex-direction: row;
  border: none;
  background-color: #254c81;
  color: white;
`;

const BottomHeader = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  height: 20vh;
`;

const LogoutImage = styled.img`
  margin-right: 5px;
  height: 14px;
`;

const Button = styled.button`
  /* width: 10vw; */
  height: 30px;
  margin: 20px;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  color: #254c81;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ListHeaderTitle = styled.p`
  font-size: 22px;
  margin: 0;
  font-weight: bold;
`;

const ListContainer = styled.div`
  width: 40vw;
  color: #254c81;
`;

export default Home;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getList as getCustomersList } from "../../api/list";

export default function useCustomers() {
  const dispatch = useDispatch();
  useEffect(() => {
    getCustomersList(dispatch).catch(console.error);
  }, [dispatch]);
}

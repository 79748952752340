import styled from "styled-components";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import Separator from "./Separator";

function UserList({
  id,
  firstname,
  lastname,
  email,
  onDeletePress,
  onUpdatePress,
}) {
  console.log("firstname", firstname);
  console.log("lastname", lastname);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <div>
          <div style={{ display: "flex" }}>
            <LastNameLabel>{lastname}</LastNameLabel>
            <FirstNameLabel>{firstname}</FirstNameLabel>
          </div>
          <EmailLabel>{email}</EmailLabel>
        </div>
        <div>
          <Button onClick={onUpdatePress}>
            <IconContext.Provider value={{ color: "#2a5084", size: "25px" }}>
              <FiEdit />
            </IconContext.Provider>
          </Button>
          <Button onClick={onDeletePress}>
            <IconContext.Provider value={{ color: "red", size: "25px" }}>
              <RiDeleteBinLine />
            </IconContext.Provider>
          </Button>
        </div>
      </div>
      <Separator width={580} color={"black"} opacity={0.2} />
    </>
  );
}

const LastNameLabel = styled.p`
  margin: 0;
  margin-right: 3px;
  font-weight: bold;
  color: #2a5084;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
`;

const FirstNameLabel = styled.p`
  margin: 0;
  color: #2a5084;
  font-weight: 500;
`;

const EmailLabel = styled.p`
  margin: 0;
  color: #2a5084;
  font-weight: 300;
  font-size: 14px;
`;

export default UserList;

import axios from "axios";
import { BASE_URL } from "../config";

export const oneSignalAdd = async (oneSignalId) => {
    let userId = localStorage.getItem("userId");
    if (userId !== 'undefined') {
        userId = JSON.parse(userId);
    }

  if (oneSignalId !== "") {
    let response = await axios
      .post(`${BASE_URL}/onesignal_id/create`, {
        onesignal_id: oneSignalId,
        customer_id: userId,
      })

      .catch((error) => console.log(error));
    return response.data;
  }
};

import axios from "axios";
import { updateCustomers } from "../../redux/slices/CustomersSlice";
import { BASE_URL } from "../config";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.common.crossDomain = true;
let token = localStorage.getItem("token");
if (token !== 'undefined') {
  token = JSON.parse(token);
}

async function UpdateUser(
  idClient,
  firstname,
  lastname,
  email,
  supervisor,
  dispatch
) {
  console.log("API SUPERVISOR", supervisor);
  const customers = {
    idClient: idClient,
    firstname: firstname,
    lastname: lastname,
    email: email,
    isSupervisor: supervisor === true ? 1 : 0,
  };
  const { error } = await axios.post(`${BASE_URL}/client/update`, customers, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
  if (error) {
    console.log(error);
  } else {
    dispatch && dispatch(updateCustomers(customers));
  }
}

export { UpdateUser };

import styled from "styled-components";
import { IconContext } from "react-icons";
import { BsBroadcast } from "react-icons/bs";
import { FiUser } from "react-icons/fi";

function CounterCard({ type, count, color, title }) {
  return (
    <MainContainer>
      <IconContext.Provider value={{ color: color, size: "40px" }}>
        {type === "visitor" && <FiUser />}
        {type === "report" && <BsBroadcast />}
      </IconContext.Provider>
      <CountTitle color={color}>{count}</CountTitle>
      <Title color={color}>{title}</Title>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 150px;
  border-radius: 10px;
  border: none;
  background-color: white;
  margin: 20px;
  box-shadow: 2px 11px 8px -5px rgba(0, 0, 0, 0.1);
`;

const CountTitle = styled.p`
  font-size: 32px;
  margin: 5px;
  font-weight: bold;
  color: ${({ color }) => (color ? color : "black")};
`;
const Title = styled.p`
  margin: 5px;
  color: ${({ color }) => (color ? color : "black")};
`;

// const Image = styled.img`
//   margin: 5px;
//   height: 40px;
//   color: ${({ color }) => (color ? color : "red")};
// `;

export default CounterCard;
